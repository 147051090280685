import React from 'react'
import { Router } from '@reach/router'
import SlideshowPreview from '../../components/SlideshowPublishedPreview'
import { AuthProvider } from '../../components/User/AuthProvider'

const App = () :JSX.Element => (
	<AuthProvider enableAnonymouseAccountCreation>
		<Router basepath="/i">
			<SlideshowPreview path="/:id" />
		</Router>
	</AuthProvider>
)

export default App
